var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "domain-service-configuration-slide-container" },
    [
      _vm.loading
        ? _c("ct-centered-spinner", { staticClass: "loading-spinner" }, [
            _c("p", { staticClass: "loading-text" }, [
              _vm._v("\n      " + _vm._s(_vm.savingMessage) + "\n    "),
            ]),
          ])
        : _vm.domainSetupComplete
        ? _c("div", [
            _c("p", [
              _vm._v("\n      Your domain\n      "),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.domain?.domain_name) + "\n      "
                ),
              ]),
              _vm._v("\n      has been setup.\n    "),
            ]),
          ])
        : _c("choose-domain", {
            attrs: { bus: _vm.bus },
            on: {
              nextStep: _vm.nextStep,
              "next-slide": function ($event) {
                return _vm.$emit("next-slide")
              },
            },
          }),
      _c(
        "div",
        { staticClass: "buttons mt-3 mb-0 d-flex flex-column flex-end" },
        [
          _vm.showPrimaryButton
            ? _c(
                "div",
                { staticClass: "slide-viewer-button-row mt-3 mb-0" },
                [
                  _c("primary-button", {
                    attrs: {
                      "button-text": _vm.primaryButtonText,
                      disabled:
                        _vm.loading ||
                        (!_vm.domainSelected && !_vm.domainCreated),
                      "aria-label": _vm.primaryButtonText + " button",
                    },
                    on: { onclick: _vm.nextStep },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }