<template>
  <div class="domain-service-configuration-slide-container">
    <ct-centered-spinner v-if="loading" class="loading-spinner">
      <p class="loading-text">
        {{ savingMessage }}
      </p>
    </ct-centered-spinner>
    <div v-else-if="domainSetupComplete">
      <p>
        Your domain
        <span class="font-weight-bold">
          {{ domain?.domain_name }}
        </span>
        has been setup.
      </p>
    </div>
    <choose-domain
      v-else
      :bus="bus"
      @nextStep="nextStep"
      @next-slide="$emit('next-slide')"
    />

    <div class="buttons mt-3 mb-0 d-flex flex-column flex-end">
      <div v-if="showPrimaryButton" class="slide-viewer-button-row mt-3 mb-0 ">
        <primary-button
          :button-text="primaryButtonText"
          :disabled="loading || (!domainSelected && !domainCreated)"
          :aria-label="primaryButtonText + ' button'"
          @onclick="nextStep"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { logDomainInteraction } from '@/components/StagelineV2/slides/serviceConfiguration/services/helper'

export default {
  name: 'DomainServiceConfiguration',
  components: {
    ChooseDomain:                 () => import('@/components/Services/Domains/ChooseDomain'),
    CtCenteredSpinner:            () => import('@/components/shared/CtCenteredSpinner'),
    PrimaryButton:                () => import('@/components/StagelineV2/shared/PrimaryButton'),
  },
  mixins: [makeToastMixin],
  props: {
    slide: null,
  },
  data() {
    return {
      bus: new Vue(),
      loading: false,
      savingMessage: '',
      domainSelected: false,
      slideStartTime: null,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'ghostMode',
    ]),
    ...mapGetters('domains', [
      'selectedDomain',
      'domain',
      'webHosting',
      'hostingTransferInfo',
      'standaloneHostingInfo',
      'activeDomainTransferRequest',
    ]),
    ...mapGetters('vouchers', [
      'voucherByProductCategory',
      'accountDomainVouchers',
      'unRedeemedVoucherByProductCategoryCompanyId',
    ]),
    primaryButtonText() {
      return !this.domainCreated && !this.hostingTransferInfo && this.slide?.config?.continue_button.text || 'Continue'
    },
    showPrimaryButton() {
      const noActiveTransferOrDomainCreated = !this.activeDomainTransferRequest || this.domainCreated
      const domainIsReady = this.domainVoucher && (this.domainCreated || this.domainSelected)

      return noActiveTransferOrDomainCreated && domainIsReady
    },
    domainVoucher() {
      return this.voucherByProductCategory('business-domain')
    },
    hasUnredeemedDomainVoucher() {
      return !!this.unRedeemedVoucherByProductCategoryCompanyId('business-domain', this.company.id)
    },
    domainCreated() {
      return this.domainVoucher && !this.hasUnredeemedDomainVoucher
    },
    serviceType() {
      return this.slide.layout_sub_type
    },
    noAvailableEmailOrHostingVoucher() {
      return !this.hostingVoucherAvailable() && !this.emailVoucherAvailable()
    },
    domainSetupComplete() {
      return this.domainCreated && !this.activeDomainTransferRequest && this.noAvailableEmailOrHostingVoucher
    },
  },
  watch: {
    domainSelected(newValue) {
      if(newValue) {
        this.scrollToBottom()
      }
    },
  },
  async mounted() {
    if(this.standaloneHostingInfo || this.hostingTransferInfo) {
      this.$emit('next-slide')
    } else {
      await this.reloadDomainVouchers()
      this.createClientSlideInteraction()

      this.bus.$on('scroll-to-bottom', this.scrollToBottom)
      this.bus.$on('navigate', await this.completeSlideInteraction)
      this.bus.$on('domain-selected', (selected) => {
        this.domainSelected = selected
      })
      this.bus.$on('log-domain-interaction', await logDomainInteraction)
    }
  },
  methods: {
    ...mapActions('domains', [
      'createDomain',
      'addService',
    ]),
    ...mapActions('companies', [
      'loadActiveServiceByType',
    ]),
    ...mapActions('vouchers', [
      'fetchAccountDomainVouchers',
    ]),
    createClientSlideInteraction() {
      if (this.ghostMode) return

      this.slideStartTime = Date.now()
    },
    async completeSlideInteraction() {
      if(!this.slideStartTime) return

      const logInfo = {
        name: 'slide_interaction',
        slideStartTime: this.slideStartTime,
      }

      await logDomainInteraction(logInfo)
    },
    scrollToBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
          })
        }, 200)
      })
    },
    async reloadDomainVouchers() {
      this.loading = true
      await this.fetchAccountDomainVouchers()
      this.loading = false
    },
    hostingVoucherAvailable() {
      return !!this.unRedeemedVoucherByProductCategoryCompanyId('business-website-hosting', this.company.id)
    },
    emailVoucherAvailable() {
      return !!this.unRedeemedVoucherByProductCategoryCompanyId('business-email', this.company.id)
    },
    shouldCreateHostingWithDomain() {
      return this.hostingVoucherAvailable() || this.emailVoucherAvailable()
    },
    async nextStep() {
      if (this.domainCreated) return this.$emit('next-slide')
      if (!this.selectedDomain) {
        this.errorToast('Error', 'Select a domain.')
        return
      }

      this.loading = true

      const createDomainResult = await this.createDomainRecord()
      if (!createDomainResult) {
        this.loading = false
        return
      }
      await this.completeSlideInteraction()

      await logDomainInteraction({
        name: 'choose-domain',
        action: 'create',
        subCategory: 'business-domain',
        objectTable: 'Domain',
        objectId: this.domain.id,
      })

      const createHostingResult = await this.createHostingRecord()
      if (!createHostingResult) {
        this.loading = false
        return
      }

      await logDomainInteraction({
        name: 'choose-domain-hosting',
        action: 'create',
        subCategory: 'business-website-hosting',
        objectTAble: 'Domain Hosting Info',
        objectId: this.webHosting.id,
      })

      this.loading = false
      this.$emit('next-slide')
    },
    async createDomainRecord() {
      let returnResult = true
      this.savingMessage = 'Creating Domain...'
      const result = await this.createDomain(this.company.id)

      if (result?.success) {
        await this.loadActiveServiceByType({
          id: this.company.id,
          type: 'business-domain',
        })
        this.successToast('Success', 'Domain has been created.')
        returnResult = true
      } else {
        returnResult = false
        this.errorToast('Error', 'Unable to create domain.')
      }
      return returnResult
    },
    async createHostingRecord() {
      let returnResult = true
      const hostingRequired = this.shouldCreateHostingWithDomain()
      if (hostingRequired) {
        this.savingMessage = 'Creating hosting...this can take a few minutes. Please do not refresh the page.'
        const result = await this.addService( {
          domainId: this.domain.id,
        })
        if (result?.success) {
          await this.loadActiveServiceByType({
            id: this.company.id,
            type: 'business-website-hosting',
          })
          await this.loadActiveServiceByType({
            id: this.company.id,
            type: 'business-email',
          })
          this.successToast('Success', 'Hosting has been created.')
          returnResult = true
        } else {
          returnResult = false
          this.errorToast('Error', 'Unable to create web hosting.')
        }
      }
      return returnResult
    },
  },
}
</script>
<style scoped lang="scss">
.domain-service-configuration-slide-container {
  .form-title {
    font-weight: bold;
  }
  .buttons {
    float: right;
  }
  .loading-spinner {
    height: 6rem !important;
  }
  .loading-text {
    width: max-content;
    word-wrap: break-word;
    max-width: 75vw;
  }
}
</style>
